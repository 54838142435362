import { Box } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";
import Div100vh from "react-div-100vh";

import Footer from "../Footer";
import LanguageSwitcher from "../LanguageSwitcher";
import NavBar from "../NavBar";
import SideBar from "../SideBar";
import TopBar from "../TopBar";

type Props = {
  navBar?: boolean;
  footer?: boolean;
  children: ReactNode;
};

const Chrome = ({ navBar, footer, children }: Props): ReactElement => {
  return (
    <Div100vh
      style={{
        display: "grid",
        gridTemplateRows: "auto 1fr",
      }}
    >
      <TopBar />
      <Box sx={{ overflow: "auto" }}>
        <Box id="top"></Box>
        {navBar && (
          <NavBar>
            <LanguageSwitcher />
            <SideBar />
          </NavBar>
        )}
        {children}
        {footer && <Footer />}
      </Box>
    </Div100vh>
  );
};

export default Chrome;
