import EmailIcon from "@mui/icons-material/Email";
import ExtensionIcon from "@mui/icons-material/Extension";
import HelpIcon from "@mui/icons-material/Help";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import NextLink from "next/link";
import { useTranslation } from "next-i18next";
import React, { ReactElement, useState } from "react";

import { useContent } from "../ContentProvider";

const SideBar = (): ReactElement => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { host } = useContent();

  return (
    <>
      <Button
        onClick={(): void => setOpen(true)}
        size="small"
        data-testid="menuButton"
        variant="contained"
        color="menu"
        name="Menu"
        aria-label="Menu"
        startIcon={<MenuRoundedIcon />}
        sx={{
          ml: 1,
          width: 32,
          height: 32,
          minWidth: 32,
          "& .MuiButton-startIcon": {
            mx: 1,
          },
        }}
      />
      <Drawer
        sx={{
          "& > .MuiPaper-root": {
            borderTopLeftRadius: 12,
            borderBottomLeftRadius: 12,
          },
        }}
        anchor="right"
        open={open}
        onClose={(): void => setOpen(false)}
      >
        <Box
          sx={{
            width: 250,
          }}
          onClick={(): void => setOpen(false)}
        >
          <List>
            <NextLink
              legacyBehavior
              href="/"
              passHref
              prefetch={process.env.NODE_ENV === "production"}
            >
              <ListItem button component="a">
                <ListItemIcon>
                  <HomeIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary={t("Home")} />
              </ListItem>
            </NextLink>
            {(host == null || host.pageAbout) && (
              <NextLink
                legacyBehavior
                href="/about"
                passHref
                prefetch={process.env.NODE_ENV === "production"}
              >
                <ListItem button component="a">
                  <ListItemIcon>
                    <InfoIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={t("About")} />
                </ListItem>
              </NextLink>
            )}
            {(host == null || host.pageFaqs) && (
              <NextLink
                legacyBehavior
                href="/faq"
                passHref
                prefetch={process.env.NODE_ENV === "production"}
              >
                <ListItem button component="a">
                  <ListItemIcon>
                    <HelpIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={t("FAQs")} />
                </ListItem>
              </NextLink>
            )}
            {(host == null || host.pageGetTheWidget) && (
              <ListItem
                button
                component="a"
                href="https://www.throughlinecare.com"
                target="_blank"
              >
                <ListItemIcon>
                  <ExtensionIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary={t("Integrations")} />
                <OpenInNewIcon fontSize="inherit" />
              </ListItem>
            )}
            {(host == null || host.pageContact) && (
              <NextLink
                legacyBehavior
                href="/contact"
                passHref
                prefetch={process.env.NODE_ENV === "production"}
              >
                <ListItem button component="a">
                  <ListItemIcon>
                    <EmailIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={t("Contact")} />
                </ListItem>
              </NextLink>
            )}
            {(host == null ||
              host.pagePrivacyPolicy ||
              host.pageTermsOfService) && <Divider />}
            {(host == null || host.pagePrivacyPolicy) && (
              <NextLink
                legacyBehavior
                href="/privacy"
                passHref
                prefetch={process.env.NODE_ENV === "production"}
              >
                <ListItem button component="a">
                  <ListItemText
                    primary={t("Privacy Policy")}
                    primaryTypographyProps={{
                      variant: "body2",
                      color: "textSecondary",
                    }}
                  />
                </ListItem>
              </NextLink>
            )}
            {(host == null || host.pageTermsOfService) && (
              <NextLink
                legacyBehavior
                href="/terms"
                passHref
                prefetch={process.env.NODE_ENV === "production"}
              >
                <ListItem button component="a">
                  <ListItemText
                    primary={t("Terms of Service")}
                    primaryTypographyProps={{
                      variant: "body2",
                      color: "textSecondary",
                    }}
                  />
                </ListItem>
              </NextLink>
            )}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default SideBar;
