import { UrlObject } from "url";

import { Link, SxProps } from "@mui/material";
import NextLink from "next/link";
import React, { ComponentProps, ReactElement, ReactNode } from "react";

type Props = {
  href: string | UrlObject;
  children?: ReactNode;
  sx?: SxProps;
  "data-testid"?: string;
  underline?: boolean;
  locale?: ComponentProps<typeof NextLink>["locale"];
};

const LinkText = ({
  href,
  children,
  sx,
  "data-testid": dataTestId,
  underline,
  locale,
}: Props): ReactElement => {
  return (
    <NextLink
      legacyBehavior
      href={href}
      passHref
      prefetch={process.env.NODE_ENV === "production"}
      locale={locale}
    >
      <Link
        sx={{
          textDecoration: underline === true ? "underline" : undefined,
          color: underline === true ? "text.primary" : undefined,
          ...sx,
        }}
        data-testid={dataTestId}
      >
        {children}
      </Link>
    </NextLink>
  );
};

export default LinkText;
